import { FC } from 'react';
import { Button } from "elements";
import { create } from 'react-modal-promise';

// @ts-ignore
import icon from "assets/svg/statmentIcon.svg";

import {
  StyledModal,
  Wrapper,
  Icon,
  Title,
  Text,
  Footer,
} from "./styled";

interface IProps {
  isOpen: boolean;
  id?: string;
  onResolve: (value: boolean) => void;
}

const ConfirmModal: FC<IProps> = ({ isOpen, id, onResolve }) => {
  const handleCancel = () => {
    onResolve(false);
  };

  const handleContinue = async () => {
    if (id) {
      onResolve(true);
    }
  }

  return (
    <StyledModal
      open={isOpen}
      footer={false}
      centered
      width={598}
      onCancel={handleCancel}
    >
      <Wrapper>
        <Icon src={icon} alt="icon" />

        <Title>Are you sure?</Title>
        <Text>Changing the area or category of your goal impacts your mission statement. To keep your current goal and mission statement intact, we recommend creating a new goal with the updated area or category.<br /><br />Do you want to continue with your changes, or start a new goal?</Text>

        <Footer>
          <Button
            styleType="secondary"
            onClick={handleContinue}
          >
            Continue With Changes
          </Button>
          <Button
            styleType="primary"
            style={{ width: 167 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Footer>
      </Wrapper>
    </StyledModal>
  );
};

const confirmModal = create(ConfirmModal);
export { confirmModal };
