// @ts-ignore
import { listAreas, createArea, deleteArea, createCategory, deleteCategory } from "api";
import { ActionTypes } from "./types";

import Notify from "utils/notify";

// @ts-ignore
import { getGPSUserId } from "utils/utility";

export const ListAreas = (domainId: string, custom: boolean = false) => async (dispatch: any, getState: any) => {
  const { client } = getState().prioritizedActions;
  const userId = getGPSUserId();

  try {
    dispatch({ type: custom ? ActionTypes.CUSTOM_LOADING : ActionTypes.LOADING });

    const filtersParams: { [key: string]: any } = {
      custom: custom
    };

    if (domainId) {
      filtersParams.subdomain = domainId;
    }

    if (custom) {
      filtersParams.user = client ? client : userId;
    }

    const { data } = await listAreas(filtersParams);

    dispatch({
      type: custom ? ActionTypes.CUSTOM_SUCCESS : ActionTypes.SUCCESS,
      payload: data,
    });
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};

export const CreateArea = (payload: FormData, callback: any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING_CREATE });

    const { data } = await createArea(payload);

    dispatch({
      type: ActionTypes.SUCCESS_CREATE,
      payload: data
    });

    Notify.success('Custom area created successfully');

    if (callback)
      callback(data);
  } catch (error) {
    console.error(error);
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR_CREATE,
      payload: error
    });

    callback(null);
  }
};

export const DeleteArea = (id: string) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING_DELETE });

    await deleteArea(id);

    dispatch({
      type: ActionTypes.SUCCESS_DELETE,
      payload: id
    });

    Notify.success('Custom area deleted successfully');
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR_DELETE,
      payload: error
    });
  }
};

export const CreateCategory = (payload: FormData, callback: any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING_CATEGORY_CREATE });

    const { data } = await createCategory(payload);

    dispatch({
      type: ActionTypes.SUCCESS_CATEGORY_CREATE,
      payload: { area: payload.get('area'), data }
    });

    Notify.success('Custom category created successfully');

    if (callback)
      callback(data);
  } catch (error) {
    console.error(error);
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR_CATEGORY_CREATE,
      payload: error
    });

    callback(null);
  }
};


export const DeleteCategory = (id: string, area: string) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING_CATEGORY_DELETE });

    await deleteCategory(id);

    dispatch({
      type: ActionTypes.SUCCESS_CATEGORY_DELETE,
      payload: { id, area}
    });

    Notify.success('Custom category deleted successfully');
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR_CATEGORY_DELETE,
      payload: error
    });
  }
};

export const SelectedArea = (area: any) => async (dispatch: any) => {
  dispatch({
    type: ActionTypes.SELECTED_AREA,
    payload: area,
  });
};

export const ListAreasNull = (decision: any) => async (dispatch: any) => {
  dispatch({
    type: ActionTypes.SUCCESS,
    payload: decision,
  });
};
