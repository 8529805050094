import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "elements";

import AreasDescription from "components/Journeys/AreasSection";

import { ReactComponent as CloseIcon } from "assets/images/journeys/close.svg";
import { ReactComponent as FlagIcon } from "assets/images/journeys/flag.svg";

import { ListAreas } from "Redux/ListAreas/action";

import {
  Background,
  Wrapper,
  Content,
  Header,
  TitleWrap,
  Title,
  Close,
  SpinnerWrapper
} from "./styled";

const AreasList = ({ journey, onClose, selectedName }) => {
  const dispatch = useDispatch();

  const { data, loading } = useSelector((state) => state.listAreaReducer);

  useEffect(() => {
    if (journey) {
      dispatch(ListAreas(journey));
    }
  }, [journey]);

  return (
    <>
      <Wrapper $active={!!journey}>
        <Header>
          <TitleWrap>
            <FlagIcon />
            <Title>{selectedName} Areas</Title>
          </TitleWrap>

          <Close type="button" onClick={onClose}>
            <CloseIcon />
          </Close>
        </Header>

        <Content>
          {loading
            ? <SpinnerWrapper>
                <Spinner variant="secondary" />
              </SpinnerWrapper>
            : <AreasDescription areas={data} />
          }
        </Content>
      </Wrapper>

      <Background $active={!!journey} onClick={onClose} />
    </>
  );
};

export default AreasList;
