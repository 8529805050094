import { useEffect, useRef, useState } from "react";
import { Button, InputField } from "elements";
import { Accordion } from "react-bootstrap";

import { ReactComponent as CollapseIcon } from "assets/images/journeys/chevron-right.svg";
import { ReactComponent as TrashBlueIcon } from "assets/images/journeys/trash-blue.svg";
import { ReactComponent as TrashDarkIcon } from "assets/images/journeys/trash-dark.svg";

import {
  Wrapper,
  Head,
  Toggle,
  Title,
  Delete,
  Body,
  BodyInner,
  // DeleteBtn
} from "./styled";

import {
  Group,
  Label,
  ActionsGroup
} from '../styled';

const MetricGroup = ({ activeKey, data, onToggle, onUnitAdd, onUnitUpdate, onUnitDelete, onMetricUpdate, onMetricDelete }) => {
  const { id, label, units } = data;

  const [isUnitEdit, setIsUnitEdit] = useState(null);
  const [unitName, setUnitName] = useState('');
  const [labelName, setLabelName] = useState(label);

  const $label = useRef(null);
  const $inputRefs = useRef([]);

  const isActive = activeKey === id;

  useEffect(() => {
    setTimeout(() => {
      // Find the first input field that is empty
      const emptyField = $inputRefs.current.find(input => input && !input.value);
  
      if (emptyField) {
        emptyField.focus();
      } else if ($label && $label.current) {
        $label.current.focus();
      }
    }, 150);
  }, [activeKey, units.length]);

  useEffect(() => {
    $inputRefs.current = $inputRefs.current.slice(0, units.length);
  }, [units]);

  const onClickHandle = () => {
    onToggle(id);
  }

  const onLabelChangeHandle = e => {
    let input = e.target.value.trim();

    if (input.length > 12) {
      input = input.slice(0, 12);
    }

    setLabelName(input);
    onMetricUpdate(input, id);
  }

  const onLabelBlurHandle = e => {
    if (e.target.value === label)
      return;
  }

  const onMetricDeleteHandle = () => {
    onMetricDelete(id);
  }

  const onDeleteHandle = (unit) => () => {
    onUnitDelete(unit.id);
  }

  const onAddUnitHandle = () => {
    const emptyField = $inputRefs.current.find(input => input && !input.value);
    
    if (emptyField) {
      emptyField.focus();
    } else {
      onUnitAdd("", id);
    }
  }

  const onUnitChangeHandle = unit => e => {
    let input = e.target.value.trim();

    if (input.length > 17) {
      input = input.slice(0, 17);
    }

    setUnitName(input);
    onUnitUpdate(input, unit.id);
  }

  const onUnitFocusHandle = unit => () => {
    setUnitName(unit.name);
    setIsUnitEdit(unit.id);
  }

  const onUnitBlurHandle = unit => e => {
    setUnitName('')
    setIsUnitEdit(null);
  }

  const isOnlyUnit = units.filter(unit => !unit.isDeleted).length === 1;

  const contentSuffix = unit => (
    <Delete type="button" className={unit === 0 ? 'ghost' : ''} title="Delete Unit" disabled={isOnlyUnit} onClick={onDeleteHandle(unit)}>
      <TrashDarkIcon />
    </Delete>
  );

  return (
    <Wrapper $active={isActive}>
      <Head>
        <Toggle $active={isActive} type="button" onClick={onClickHandle}>
          <CollapseIcon />
          <Title $active={isActive}>{label}</Title>
        </Toggle>

        {isActive && (<Delete onClick={onMetricDeleteHandle} type="button" title="Delete Metric">
          <TrashBlueIcon />
        </Delete>)}
      </Head>

      <Accordion.Collapse as={Body} eventKey={id}>
        <BodyInner>
          <Group>
            <Label>Label*</Label>
            <InputField
              size="md"
              value={labelName}
              placeholder="Label Name"
              ref={$label}
              required
              onChange={onLabelChangeHandle}
              onBlur={onLabelBlurHandle}
              onKeyDown={e => e.key === 'Enter' && onLabelBlurHandle(e)}
            />
          </Group>

          {(units.filter(unit => !unit.isDeleted).length) ? <Group>
            <Label>Unit*</Label>

            {units.filter(unit => !unit.isDeleted).map((unit, i) => (
              <InputField
                size="md"
                value={isUnitEdit === unit.id ? unitName : unit.name}
                placeholder="Unit Name"
                required key={unit.id}
                ref={el => ($inputRefs.current[i] = el)}
                suffix={contentSuffix(unit)}
                onChange={onUnitChangeHandle(unit)}
                onFocus={onUnitFocusHandle(unit)}
                onBlur={onUnitBlurHandle(unit)}
                onKeyDown={e => e.key === 'Enter' && onUnitBlurHandle(unit)}
              />
            ))}
          </Group> : null}

          <ActionsGroup>
            <Button
              type="button"
              styleType="link-primary"
              onClick={onAddUnitHandle}
            >
              Add Unit
            </Button>
          </ActionsGroup>
        </BodyInner>
      </Accordion.Collapse>
    </Wrapper>
  );
}

export default MetricGroup;
