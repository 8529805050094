import { ActionTypes, IAction } from "./types";

let INITIAL_STATE = {
  loading: false,
  customLoading: false,
  error: null,
  data: [],
  customData: [],
  selectedArea: null,
};

export default (state = INITIAL_STATE, action: IAction) => {
  switch (action.type) {
    case ActionTypes.LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.CUSTOM_LOADING:
      return {
        ...state,
        customLoading: true
      };

    case ActionTypes.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case ActionTypes.CUSTOM_SUCCESS:
      return {
        ...state,
        customLoading: false,
        customData: action.payload,
      };

    case ActionTypes.SUCCESS_CREATE:
      return {
        ...state,
        customLoading: false,
        customData: [
          ...state.customData,
          action.payload
        ]
      };

    case ActionTypes.SUCCESS_DELETE:
      return {
        ...state,
        customLoading: false,
        customData: state.customData.filter((item: any) => item.id !== action.payload)
      };

    case ActionTypes.SUCCESS_CATEGORY_CREATE:
      // payload.area = id of the area
      // payload.data = category data

      return {
        ...state,
        customLoading: false,
        data: [
          ...state.data.map((item: any) => {
            if (item.id === action.payload.area) {
              item.categories = [
                ...item.categories,
                action.payload.data
              ];
            }

            return item;
          })
        ],
        customData: [
          ...state.customData.map((item: any) => {
            if (item.id === action.payload.area) {
              item.categories = [
                ...item.categories,
                action.payload.data
              ];
            }

            return item;
          })
        ]
      };

    case ActionTypes.SUCCESS_CATEGORY_DELETE:
      // payload.area id of the area
      // payload.id = id of the category

      return {
        ...state,
        customLoading: false,
        data: [
          ...state.data.map((item: any) => {
            if (item.id === action.payload.area) {
              item.categories = item.categories.filter((cat: any) => cat.id !== action.payload.id);
            }

            return item;
          })
        ],
        customData: [
          ...state.customData.map((item: any) => {
            if (item.id === action.payload.area) {
              item.categories = item.categories.filter((cat: any) => cat.id !== action.payload.id);
            }

            return item;
          })
        ]
      };

    case ActionTypes.SELECTED_AREA:
      return {
        ...state,
        loading: false,
        selectedArea: action.payload,
      };

    case ActionTypes.ERROR:
      return {
        ...state,
        loading: false,
        customLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
