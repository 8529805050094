import React, {
  ChangeEvent,
  KeyboardEvent,
  CSSProperties,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";

import {
  Counter,
  Wrapper,
  TextareaStyled,
} from "./styled";

export interface InputAreaProps {
  value?: string;
  // defaultValue?: string;
  selected?: boolean;
  max?: number;
  counter?: boolean;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  borderless?: boolean;
  style?: CSSProperties;
  counterStyle?: CSSProperties;
  size?: "sm";
  props?: object;
  readOnly?: boolean;
  error?: boolean;
}

const InputArea = forwardRef(
  (
    {
      value = "",
      // defaultValue = "",
      selected = false,
      max = 9999,
      counter,
      placeholder = "",
      disabled,
      error,
      onChange,
      onBlur,
      onKeyDown,
      counterStyle,
      borderless,
      size,
      ...props
    }: InputAreaProps,
    ref
  ) => {
    const $contentEditableRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => ({
      focus: () => {
        const $input = $contentEditableRef.current;

        if ($input) {
          $input.focus();

          const length = $input.value.length;
          $input.setSelectionRange(length, length);
        }

        if ($contentEditableRef.current) {
          $contentEditableRef.current.focus();
        }
      },
      select: () => {
        if ($contentEditableRef.current) {
          $contentEditableRef.current.select();
        }
      },
      click: () => {
        if ($contentEditableRef.current) {
          $contentEditableRef.current.click();
        }
      },
    }));

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const charLimit = max;

      // Remove newline characters
      let newValue = e.target.value.replace(/\n/g, '');

      if (newValue.length > charLimit) {
        newValue = newValue.slice(0, charLimit);
      }

      e.target.value = newValue;
      onChange?.(e);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }

      onKeyDown?.(e);
    };

    const editableComponent = (
      <TextareaStyled
        value={value}
        // defaultValue={defaultValue}
        onChange={handleChange}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        async={true}
        placeholder={placeholder}
        ref={$contentEditableRef}
        $active={selected}
        $borderless={borderless}
        $size={size}
        $error={error}
        $counter={counter}
        $disabled={disabled}
        {...props}
      />
    );

    return counter ? (
      <Wrapper>
        {editableComponent}
        <Counter style={counterStyle}>
          {value.length}/{max}
        </Counter>
      </Wrapper>
    ) : (
      editableComponent
    );
  }
);

export default InputArea;
