import {
  getCustomMetrics,
  createCustomMetric,
  updateCustomMetric,
  deleteCustomUnit,
  updateCustomUnit,
  deleteCustomMetric,
  bulkManageMetrics
} from "api";

import { ActionTypes } from "./types";

import { getGPSUserId } from "utils/utility";

import Notify from "utils/notify";

export const GetCustomMetrics = (callback) => async (dispatch, useState) => {
  const { SelectedClient } = useState().globalReducer;
  const userId = getGPSUserId();

  try {
    dispatch({ type: ActionTypes.GET_LOADING });

    const { data } = await getCustomMetrics(SelectedClient?.id || userId);

    dispatch({
      type: ActionTypes.GET_SUCCESS,
      payload: data,
    });

    if (callback)
      callback();
  } catch (error) {
    Notify.error(error, true);
    dispatch({
      type: ActionTypes.GET_ERROR,
      payload: error,
    });
  }
};

export const CreateCustomMetric = (payload, callback) => async (dispatch, useState) => {
  try {
    dispatch({ type: ActionTypes.ADD_LOADING });

    const { SelectedClient } = useState().globalReducer;

    if (SelectedClient) {
      payload.append('client_user_id', SelectedClient.id);
    }

    const { data } = await createCustomMetric(payload);

    dispatch({
      type: ActionTypes.ADD_SUCCESS,
      payload: data,
    });

    if (callback)
      callback(data);
  } catch (error) {
    Notify.error(error, true);

    if (callback)
      callback(false);

    dispatch({
      type: ActionTypes.ADD_ERROR,
      payload: error,
    });
  }
};

export const DeleteCustomUnit = (id, callback) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.DELETE_UNIT_LOADING });

    await deleteCustomUnit(id);

    dispatch({
      type: ActionTypes.DELETE_UNIT_SUCCESS,
      payload: id,
    });

    if (callback)
      callback();
  } catch (error) {
    Notify.error(error, true);
    dispatch({
      type: ActionTypes.DELETE_UNIT_ERROR,
      payload: error,
    });
  }
};

export const UpdateCustomMetric = (payload, id, callback) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_LOADING });

    const { data } = await updateCustomMetric(payload, id);

    dispatch({
      type: ActionTypes.UPDATE_SUCCESS,
      payload: data,
    });

    if (callback)
      callback();
  } catch (error) {
    Notify.error(error, true);
    dispatch({
      type: ActionTypes.UPDATE_ERROR,
      payload: error,
    });
  }
};

export const DeleteCustomMetric = (id, callback) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.DELETE_LOADING });

    await deleteCustomMetric(id);

    dispatch({
      type: ActionTypes.DELETE_SUCCESS,
      payload: id,
    });

    if (callback)
      callback();
  } catch (error) {
    Notify.error(error, true);
    dispatch({
      type: ActionTypes.DELETE_ERROR,
      payload: error,
    });
  }
};

export const CreateCustomUnit = (payload, id, callback) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.ADD_UNIT_LOADING });

    const { data } = await updateCustomMetric(payload, id);

    dispatch({
      type: ActionTypes.ADD_UNIT_SUCCESS,
      payload: data,
    });

    if (callback)
      callback();
  } catch (error) {
    Notify.error(error, true);
    dispatch({
      type: ActionTypes.ADD_UNIT_ERROR,
      payload: error,
    });
  }
};

export const UpdateCustomUnit = (payload, id, callback) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_UNIT_LOADING });

    const { data } = await updateCustomUnit(payload, id);

    dispatch({
      type: ActionTypes.UPDATE_UNIT_SUCCESS,
      payload: data,
    });

    if (callback)
      callback();
  } catch (error) {
    Notify.error(error, true);
    dispatch({
      type: ActionTypes.UPDATE_UNIT_ERROR,
      payload: error,
    });
  }
};

export const BulkManageMetrics = (payload, callback) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_ALL_LOADING });

    await bulkManageMetrics(payload);

    dispatch({
      type: ActionTypes.UPDATE_ALL_SUCCESS
    });

    dispatch(GetCustomMetrics(() => {
      Notify.success("Metrics updated successfully.");

      if (callback)
        callback();
    }));
  } catch (error) {
    Notify.error(error, true);
    dispatch({
      type: ActionTypes.UPDATE_ALL_ERROR,
      payload: error,
    });
  }
};
