import { ActionTypes } from "./types";

let INITIAL_STATE = {
  data: [],
  correlationScore: 0,
  loading: false,
  success: false,
  error: null
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionTypes.LOADING:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        correlationScore: action.payload.correlationScore,
        loading: false,
        success: true,
      };

    case ActionTypes.ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload
      };

    default:
      return state;
  }
}
