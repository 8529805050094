import moment from "moment";
import { useEffect, useRef, useCallback } from "react";
import store from "Redux/store";

import { abbreviateNumber } from "js-abbreviation-number";
import { useDispatch, useSelector } from "react-redux";
import { CheckAuth } from "Redux/Token/action";

export const isLoggedIn = () => {
  const token = localStorage.getItem("token");
  return token;
};

// export const getAreaIcon = (code) => {
//   switch (code) {
//     case 'famil':
//       return familyIcon;
//     case 'healt':
//       return healthIcon;
//     case 'partn':
//       return partnerIcon;
//     case 'growt':
//       return growthIcon;
//     case 'caree':
//       return careerIcon;
//     case 'contr':
//       return contributionIcon;
//     case 'finan':
//       return financeIcon;
//     case 'socia':
//       return socialIcon;
//     case 'leisu':
//       return leisureIcon;
//     case 'envir':
//     default:
//       return environmentIcon;
//   }
// };

export const getAreaColor = (code) => {
  switch (code) {
    case "famil":
      return "#10A3B7";
    case "healt":
      return "#2E99E7";
    case "partn":
      return "#20A58D";
    case "growt":
      return "#9D9655";
    case "caree":
      return "#BA876A";
    case "contr":
      return "#24A431";
    case "finan":
      return "#6B7DDC";
    case "socia":
      return "#9E64CC";
    case "leisu":
      return "#CC6181";
    case "envir":
    default:
      return "#DD6868";
  }
};

export const getAreaBackgroundColor = (code) => {
  switch (code) {
    case "famil":
      return "#E7F6F8";
    case "healt":
      return "#EAF5FD";
    case "partn":
      return "#E9F6F4";
    case "growt":
      return "#F5F5EE";
    case "caree":
      return "#F8F3F0";
    case "contr":
      return "#E9F6EA";
    case "finan":
      return "#F0F2FB";
    case "socia":
      return "#F5F0FA";
    case "leisu":
      return "#FAEFF2";
    case "envir":
    default:
      return "#FCF0F0";
  }
};

// NOTE: returns start and end dates in ISO format
export const getStartEndDates = (startDate, endDate) => {
  let start = new Date(startDate).setHours("00");
  start = new Date(start).setMinutes("01");
  start = new Date(start).toISOString();

  let end = new Date(endDate).setHours("23");
  end = new Date(end).setMinutes("59");
  end = new Date(end).toISOString();

  return { start, end };
};

export const getStartEndDatesV2 = (startDate, endDate) => {
  return {
    start: moment(startDate).format("YYYY-MM-DD"),
    end: moment(endDate).format("YYYY-MM-DD"),
  };
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getDateOfSelectedWeek = (selectedWeek, day) => {
  const selected = selectedWeek.map((d) => moment(d).format("YYYY-MM-DD"));
  switch (day) {
    case "mon":
      return selected[0];
    case "tue":
      return selected[1];
    case "wed":
      return selected[2];
    case "thu":
      return selected[3];
    case "fri":
      return selected[4];
    case "sat":
      return selected[5];
    case "sun":
      return selected[6];
    default:
      break;
  }
};

export const getAreaSubtitle = (categories) => {
  return categories.map(({ category }) => category.name).join(", ");
};

export const convertMinToHr = (value) => {
  const duration = value.duration;
  const calculatedDuration =
    duration > 60 ? Math.round(duration / 60) + "H" : duration + "M";
  return calculatedDuration;
};

export const getWeekDays = (weekStart) => {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(moment(weekStart).add(i, "days").toDate());
  }
  return days;
};

export const getWeekRange = (date) => {
  let mDate = moment(date);

  if (mDate.isSame(moment(date).startOf("week"), "day")) {
    mDate.subtract(1, "day");
  }

  const weekDay = mDate.toDate();

  return {
    from: moment(weekDay).startOf("week").add(1, "days").toDate(),
    to: moment(weekDay).endOf("week").add(1, "days").toDate(),
  };
};

export const getFirstAvailableWeight = (existingWeights = []) => {
  const weights = Array.from({ length: 10 }, (_, i) => i + 1).sort(
    (a, b) => b - a
  );
  return weights.find((w) => {
    if (!existingWeights.includes(w)) return w;
  });
};

export const sortHabits = (habits) => {
  const allWeights = Array.from({ length: 10 }, (_, i) => i + 1).sort(
    (a, b) => b - a
  );
  const sortedHabits = [];
  allWeights.forEach((weight) => {
    const habitWithPositiveWeight = habits.find(
      (habit) => habit.weight === weight
    );
    if (habitWithPositiveWeight) {
      sortedHabits.push(habitWithPositiveWeight);
    }
    const habitWithNegativeWeight = habits.find(
      (habit) => habit.weight === -weight
    );
    if (habitWithNegativeWeight) {
      sortedHabits.push(habitWithNegativeWeight);
    }
  });
  return sortedHabits;
};

export const calculateNetReturn = (months = [], obj = {}) => {
  if (!months.length) return 0;
  const previousMonthIncome = obj[`${months[0]}_points`] || 0;
  const currentMonthIncome = obj[`${months[1]}_points`] || 0;
  return abbreviateNumber(
    Math.round(currentMonthIncome - previousMonthIncome),
    1
  );
};

export const calculateNetReturnPercentage = (
  balanceOne,
  balanceTwo,
  isBadHabit = false
) => {
  const profit = Math.round(balanceTwo - balanceOne);
  if (balanceOne === 0) {
    return "-";
  }
  const value = Math.round((+profit / balanceOne) * 100);
  return isBadHabit ? `-${Math.abs(value)}%` : `${value}%`;
};

export const getNetReturnColorCode = (balanceOne, balanceTwo) => {
  if (balanceOne === 0 && balanceTwo === 0) return 0;

  if (balanceOne === 0) return "gray";
  if (balanceTwo > balanceOne) {
    return "green";
  } else if (balanceTwo < balanceOne) {
    return "red";
  } else {
    return "gray";
  }
};

export const formatNumber = (number, decimals, dec_point, thousands_sep) => {
  // *     example 1: number_format(1234.56);
  // *     returns 1: '1,235'
  // *     example 2: number_format(1234.56, 2, ',', ' ');
  // *     returns 2: '1 234,56'
  // *     example 3: number_format(1234.5678, 2, '.', '');
  // *     returns 3: '1234.57'
  // *     example 4: number_format(67, 2, ',', '.');
  // *     returns 4: '67,00'
  // *     example 5: number_format(1000);
  // *     returns 5: '1,000'
  // *     example 6: number_format(67.311, 2);
  // *     returns 6: '67.31'
  // *     example 7: number_format(1000.55, 1);
  // *     returns 7: '1,000.6'
  // *     example 8: number_format(67000, 5, ',', '.');
  // *     returns 8: '67.000,00000'
  // *     example 9: number_format(0.9, 0);
  // *     returns 9: '1'
  // *    example 10: number_format('1.20', 2);
  // *    returns 10: '1.20'
  // *    example 11: number_format('1.20', 4);
  // *    returns 11: '1.2000'
  // *    example 12: number_format('1.2000', 3);
  // *    returns 12: '1.200'


  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    toFixedFix = function (n, prec) {
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      var k = Math.pow(10, prec);
      return Math.round(n * k) / k;
    },
    s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
};

/* export const calculateNetReturnPercentage = (
  months = [],
  obj = {},
  isBadHabit = false,
) => {
  if (!months.length) return 0;
  const previousMonthIncome = obj[`${months[0]}_points`] || 0;
  const currentMonthIncome = obj[`${months[1]}_points`] || 0;
  const profit = Math.round(currentMonthIncome - previousMonthIncome);
  if (previousMonthIncome === 0) {
    return '-';
  }
  const value = Math.round((+profit / previousMonthIncome) * 100);
  return isBadHabit ? `-${Math.abs(value)}%` : `${value}%`;
}; */

/* export const getNetReturnColorCode = (months = [], obj = {}, isBadHabit = false) => {
  if (!months.length) return 0;
  const previousMonthIncome = obj[`${months[0]}_points`];
  const currentMonthIncome = obj[`${months[1]}_points`];
  if (previousMonthIncome === 0) return 'gray';
  if (currentMonthIncome > previousMonthIncome) {
    return 'green';
  } else if (currentMonthIncome < previousMonthIncome) {
    return 'red';
  } else {
    return 'gray';
  }
}; */

export const getTime = (str) => {
  const [hours, minutes] = str?.split(":");
  return hours + " hrs " + minutes + " min";
};

export const userTypes = { client: "Client", coach: "Coach" };

export const validateURL = (string, split, index) => {
  return string?.split(split)[index];
};

export const activeTabHandler = (validatedURL, route, activeStyle, inActiveStyle) => {
  if (('/' + validatedURL) === route)
    return activeStyle;
  return inActiveStyle;
};

export function getAppBaseURL() {
  try {
    const parsedURL = new URL(window.location.href);
    return parsedURL.hostname;
  } catch (e) {
    return null;
  }
}

export function getGPSUserId() {
  const state = store.getState();
  const gps = state.userDetailReducer?.userDetail?.external_accounts?.find(project => project['goal_setting_workbook']);

  return gps
    ? gps.goal_setting_workbook
    : null;
}

export function getToken() {
  const state = store.getState();
  const token = state.tokenReducer.token || null;

  return token;
}

export function useToken() {
  const dispatch = useDispatch();
  const { token, success, loading } = useSelector((state) => state.tokenReducer);

  useEffect(() => {
    if (!success && !token) {
      dispatch(CheckAuth());
    }
  }, []);

  return {
    token,
    success,
    loading
  }
}

export const useDebouncedUpdates = (updateFunction, delay = 500) => {
  const debouncedFunctionsRef = useRef({});

  const createDebouncedFunction = useCallback((key) => {
    debouncedFunctionsRef.current[key] = _.debounce((...args) => {
      updateFunction(key, ...args);
    }, delay);
  }, [updateFunction, delay]);

  const getDebouncedFunction = useCallback((key) => {
    if (!debouncedFunctionsRef.current[key]) {
      createDebouncedFunction(key);
    }
    return debouncedFunctionsRef.current[key];
  }, [createDebouncedFunction]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      Object.values(debouncedFunctionsRef.current).forEach(debouncedFn => {
        debouncedFn.cancel();
      });
    };
  }, []);

  return getDebouncedFunction;
};

export const buildUrl = (url, params) => {
  const queryString = Object.keys(params)
    .filter(
      (key) =>
        params[key] !== "" && params[key] !== undefined && params[key] !== null
    )
    .map((key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        // Join array values with a comma
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          value.join(",")
        )}`;
      } else {
        // Directly encode the string representation of the value
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          String(value)
        )}`;
      }
    })
    .join("&");
  return url + (queryString ? `?${queryString}` : "");
};
