import { FC, useState } from "react";
import { Modal } from "antd";

import MissionStatement from "./MissionStatement";

// @ts-ignore
import VideoPlayer from "../Player";

// @ts-ignore
import useDeviceType from "../../../Hooks/ResponsiveHook";

// @ts-ignore
import msIcon from "assets/svg/ms-new.svg";

// @ts-ignore
import msPreview from "assets/images/ms-tutorial-preview.png";

// @ts-ignore
import cameraIcon from "assets/svg/video-camera.svg";

import {
  Container,
  Content,

  // Header
  Header,
  Icon,
  Heading,
  Title,
  Text,

  // Body
  Body,
  Group,
  Col,
  FormLabel,
  FormInput,

  Info,
  Counter,
  Video,

  Tutorial,
  TutorialBadge,
  TutorialText
} from "./styled";

// @ts-ignore
import styles from "./styles.module.scss";

interface IProps {
  onClose: () => void;
}

const Modern: FC<IProps> = ({ onClose }) => {
  const screenSizes = useDeviceType();

  const isMobile = screenSizes === "mobile";
  const isTablet = screenSizes === "iPad";

  const [isPlayVideo, setIsPlayVideo] = useState(false);

  const modalStyles =
  isMobile || isTablet
    ? {
        top: 0,
        left: 0,
        padding: 0,
        margin: 0,
        height: "auto",
        minHeight: "100vh",
        width: "100vw",
        maxWidth: "100vw",
      }
    : { maxHeight: "795px" };

  const modalBodyStyles =
    isMobile || isTablet
      ? { padding: 0, margin: 0, height: "100%", width: "100%" }
      : { padding: 0, height: "100%", overflow: "hidden", borderRadius: 20 };

  const handlePlayVideoToggle = () => {
    setIsPlayVideo(prev => !prev);
  }

  return (
    <Container>
      <Modal
        style={modalStyles}
        bodyStyle={modalBodyStyles}
        className={styles.modal}
        width={isMobile ? "100vw" : "1220px"}
        centered
        footer={null}
        open={true}
        maskClosable={false}
        onCancel={onClose}
      >
        <Content>
          <Header>
            <Icon src={msIcon} alt=""></Icon>
            <Heading>
              <Title>Mission Statement</Title>
              <Text>Create powerful mission statements for your selected areas to drive meaningful goals.</Text>
            </Heading>
          </Header>

          <Body>
            <Group>
              <Col>
                <FormLabel>Area</FormLabel>
                <FormInput>Health</FormInput>

                <Info>
                  <Counter>1</Counter>
                  <Heading>
                    <Title $sm>Select an Area for Improvement</Title>
                    <Text>Choose an area that needs attention. Think about what's lacking and where you want to focus your resources.</Text>
                  </Heading>
                </Info>
              </Col>
              <Col>
                <FormLabel>Category</FormLabel>
                <FormInput>Sleep</FormInput>

                <Info>
                  <Counter>2</Counter>
                  <Heading>
                    <Title $sm>Specify Your Category</Title>
                    <Text>Within your chosen area, select a specific category that needs the greatest improvement.</Text>
                  </Heading>
                </Info>
              </Col>
            </Group>

            <Group $last>
              <Col>
                <MissionStatement />
              </Col>

              <Col>
                <Info>
                  <Counter>3</Counter>
                  <Heading>
                    <Title $sm>Create Your Mission Statement</Title>
                    <Text>Define your driving purpose for this area and category. Your mission statement will guide your goal-setting and actions.</Text>

                    <Video src={msPreview} alt="" onClick={handlePlayVideoToggle}></Video>

                    <Tutorial>
                      <TutorialBadge>
                        <img src={cameraIcon} alt="" />
                        <span>Tutorial</span>
                      </TutorialBadge>
                      <TutorialText>Learn how to create your mission statement</TutorialText>
                    </Tutorial>
                  </Heading>
                </Info>
              </Col>
            </Group>
          </Body>
        </Content>
      </Modal>

      <VideoPlayer
        isOpen={isPlayVideo}
        step={1}
        onClose={handlePlayVideoToggle}
      />
    </Container>
  );
};

export default Modern;
