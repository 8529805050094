export const ActionTypes = {
  LOADING: "LIST_AREA_LOADING",
  CUSTOM_LOADING: "LIST_AREA_CUSTOM_LOADING",
  ERROR: "LIST_AREA_ERROR",
  SUCCESS: "LIST_AREA_SUCCESS",

  CUSTOM_SUCCESS: "LIST_AREA_CUSTOM_SUCCESS",
  SELECTED_AREA: "SELECTED_AREA",

  LOADING_CREATE: "AREA_LOADING_CREATE",
  SUCCESS_CREATE: "AREA_SUCCESS_CREATE",
  ERROR_CREATE: "AREA_ERROR_CREATE",

  LOADING_DELETE: "AREA_LOADING_DELETE",
  SUCCESS_DELETE: "AREA_SUCCESS_DELETE",
  ERROR_DELETE: "AREA_ERROR_DELETE",

  LOADING_CATEGORY_CREATE: "AREA_CATEGORY_LOADING_CREATE",
  SUCCESS_CATEGORY_CREATE: "AREA_CATEGORY_SUCCESS_CREATE",
  ERROR_CATEGORY_CREATE: "AREA_CATEGORY_ERROR_CREATE",

  LOADING_CATEGORY_DELETE: "AREA_CATEGORY_LOADING_DELETE",
  SUCCESS_CATEGORY_DELETE: "AREA_CATEGORY_SUCCESS_DELETE",
  ERROR_CATEGORY_DELETE: "AREA_CATEGORY_ERROR_DELETE"
};

export interface IAction {
  type: string;
  payload: any;
}
