import { FC, useState, useEffect, useRef } from "react";
import { Switch } from 'antd';
import { Button } from 'elements';

import Option, { IOption } from './Option';

import { items as data } from "../../data";

// @ts-ignore
// import Missionchip from "atoms/MissionElement/Missionchip/Missionchip";

// @ts-ignore
import Stars from "assets/svg/stars.svg";

// @ts-ignore
import ReLoad from "assets/svg/reload.svg";

// @ts-ignore
import reloadIcon from "assets/images/reload.svg";

// @ts-ignore
import MenuExpandLeft from "assets/svg/MenuExpandLeft.svg";

// @ts-ignore
import assistantLogo from "assets/images/assistant-logo.svg";

// @ts-ignore
import loaderIcon from "assets/images/assistant-loader.gif";

import {
  MainWrapper,
  Body,

  AiSuggestionContianer,
  Header,
  CloseBtn,
  HeaderWrap,
  HeaderTitle,
  SubHeader,
  SubHeaderTitle,
  ChipsWrapper,
  Footer,
  Image,
  RegGernerateButton,
  Wrapper,

  // Loader
  LoaderWrapper,
  LoaderFooter,
  LoaderBody,
  LoaderText,
  LoaderImg
} from "./styled";

import { websocketConnection } from "utils/websocket";

enum DataType {
  ROLES = 1,
  VALUES = 2,
  PRINCIPLES = 3,
  CODE = 4,
  PURPOSE = 5,
  VISION = 6,
}

interface IGoalData {
  category: string;
  area: string;
  domain: string;
}

interface IRole {
  label: string;
}

interface IValue {
  label: string;
}

interface IMissionStatement {
  roles: IRole[];
  values: IValue[];
  principle: { name: string };
  code_of_conduct: { name: string };
  purpose: { name: string };
  vision: { name: string };
}

interface IPromps {
  principle: string;
  code_of_conduct: string;
  purpose: string;
  vision: string;
}

interface IProps {
  step: number;
  goal: IGoalData;
  missionStatement: IMissionStatement;
  prompts: IPromps;
  onSelect: (items: IOption[], dataType: number) => void;
  onClose: () => void;
}

interface IItem {
  id: string;
  text: string;
}

const getDataType = (step: number): DataType => {
  const stepMapping: Record<number, DataType> = {
    1: DataType.ROLES,
    2: DataType.VALUES,
    3: DataType.PRINCIPLES,
    4: DataType.CODE,
    5: DataType.PURPOSE,
    6: DataType.VISION,
  };

  return stepMapping[step];
}

function randomID(): string {
  return `id_` + Math.floor(10000 + Math.random() * 90000);
}

const increaseBy = (num: number) => {
  let counter = num;

  return () => {
    const result = counter;
    counter += 50;
    return result;
  };
}

interface IRenderLoader {
  title: string;
  loading: boolean;
  onRefresh: () => void;
}

const Loader: FC<IRenderLoader> = ({ title, loading, onRefresh }) => {
  const [isRefreshVisible, setRefreshVisible] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const animateDelayCounter = increaseBy(100);

  const showRefreshButton = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => setRefreshVisible(true), 5000);
  };

  useEffect(() => {
    setRefreshVisible(false);
    showRefreshButton();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleClick = () => {
    setRefreshVisible(false);
    onRefresh();
    showRefreshButton();
  };

  return (
    <LoaderWrapper>
      <LoaderBody>
        <LoaderImg
          src={assistantLogo}
          width={69}
          height={69}
          $animate={loading}
          $animateDelay={animateDelayCounter()}
          alt=""
        />
        <LoaderText
          $animate={loading}
          $animateDelay={animateDelayCounter()}
        >
          Suggesting {title}...
        </LoaderText>
        <LoaderImg
          src={loaderIcon}
          width={52}
          height={53}
          $animate={loading}
          $animateDelay={animateDelayCounter()}
          alt="Loading"
        />
      </LoaderBody>

      <LoaderFooter $animate={isRefreshVisible}>
        <Button onClick={handleClick} styleType="secondary">
          <img src={reloadIcon} width={16} height={16} alt="Reload" />
          Refresh
        </Button>
      </LoaderFooter>
    </LoaderWrapper>
  );
};

const AIHelper: FC<IProps> = ({ step, goal, missionStatement, prompts, onSelect, onClose }) => {
  const [isSwitch, setIsSwitch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [items, setItems] = useState<IItem[]>([]);
  const [isShowMore, setIsShowMore] = useState(false);

  const [selectedItems, setSelectedItems] = useState<IOption[]>([]);

  const dataTypeRef = useRef<DataType>(getDataType(step));
  const dataType = getDataType(step);

  useEffect(() => {
    if (isSwitch) {
      setLoading(true);
    }

    websocketConnection.connect(() => {
      if (isSwitch) {
        handleRegenerate();
      }
    }, handleError, handleErrorClose);
    websocketConnection.setMessageCallback(handleMessage);

    if (!isSwitch) {
      setItems([]);
      setIsShowMore(false);
    }

    return () => {
      websocketConnection.close();
    };
  }, [step, isSwitch]);

  useEffect(() => {
    if (dataTypeRef.current !== dataType) {
      dataTypeRef.current = dataType;
    }
  }, [dataType]);

  const handleSwitchToggle = () => {
    setIsSwitch(prev => !prev);
  };

  const handleSuggest = () => {
    const endpointMapping: Record<DataType, string> = {
      [DataType.ROLES]: "suggest_roles",
      [DataType.VALUES]: "suggest_values",
      [DataType.PRINCIPLES]: "suggest_principles",
      [DataType.CODE]: "suggest_code_of_conduct",
      [DataType.PURPOSE]: "suggest_purpose",
      [DataType.VISION]: "suggest_vision",
    };

    const getEndpoint = () => {
      const baseData = {
        domain: goal?.domain,
        area: goal?.area,
        category: goal?.category
      };
      
      const endpoint = endpointMapping[dataType];
      let additionalData = {};

      if (dataType >= DataType.ROLES) {
        additionalData = { ...additionalData, previous_suggested_roles: missionStatement.roles.map((role) => role.label) };
      }

      if (dataType >= DataType.VALUES) {
        additionalData = { ...additionalData, role: missionStatement.roles[0]?.label, previous_suggested_values: missionStatement.values.map((value) => value.label) };
      }

      if (dataType >= DataType.PRINCIPLES) {
        additionalData = { ...additionalData, value: missionStatement.values[0]?.label, auto_completion_text: prompts.principle, previous_suggested_principles: missionStatement.principle?.name };
      }

      if (dataType >= DataType.CODE) {
        additionalData = { ...additionalData, principle: missionStatement.principle?.name, auto_completion_text: prompts.code_of_conduct, previous_suggested_codes: missionStatement.code_of_conduct?.name };
      }

      if (dataType >= DataType.PURPOSE) {
        additionalData = { ...additionalData, code_of_conduct: missionStatement.code_of_conduct?.name, auto_completion_text: prompts.purpose, previous_suggested_purposes: missionStatement.purpose?.name };
      }

      if (dataType >= DataType.VISION) {
        additionalData = { ...additionalData, purpose: missionStatement.purpose?.name, auto_completion_text: prompts.vision, previous_suggested_visions: missionStatement.vision?.name };
      }

      return {
        endpoint,
        data: { ...baseData, ...additionalData },
      };
    };

    websocketConnection.send(JSON.stringify(getEndpoint()));
  };

  const handleErrorClose = () => {
    if (loading) {
      if (!error) {
        handleRegenerate();
      }
    }
  }

  const handleMessage = (message: string) => {
    const res = JSON.parse(message);

    if (res.ping || res.pong)
      return;

    const processItems = (items: string[]) => {
      return items.map(
        (item: string) => ({ id: randomID(), text: item } as IOption)
      );
    };

    if (res?.message) {
      try {
        const itemsMap: Record<DataType, string[]> = {
          [DataType.ROLES]: res.message.roles,
          [DataType.VALUES]: res.message.values,
          [DataType.PRINCIPLES]: (res.message?.principles || res.message?.Principles),
          [DataType.CODE]: res.message.code_of_conduct,
          [DataType.PURPOSE]: res.message.purposes,
          [DataType.VISION]: res.message.visions,
        };

        const items = itemsMap[dataTypeRef.current];

        // Validation to ensure items is an array of strings
        const isArrayOfStrings = (arr: any[]): arr is string[] => arr.every(item => typeof item === 'string');

        if (!isArrayOfStrings(items)) {
          handleRegenerate();
          return false;
        }

        if (items) {
          const response: IOption[] = processItems(items);
          setItems(response);
          setLoading(false);
        }
      } catch (e) {}
    }
  };

  const handleError = () => {
    if (!error) {
      handleRegenerate();
    } else {
      setLoading(false);
    }
  }

  const handleRegenerate = () => {
    setLoading(true);
    setIsShowMore(false);

    if (error) {
      websocketConnection.connect(handleSuggest, handleError);
      setError(false);
    }
    else {
      handleSuggest();
    }
  };

  const handleRefresh = () => {
    websocketConnection.close();
    websocketConnection.connect(handleSuggest, handleError);
  }

  const handleSelect = (item: IOption) => {
    setSelectedItems((prev) =>
      step >= 3
        ? [item] // Radio button behavior for step 3 or more
        : prev.some((i) => i.id === item.id)
          ? prev.filter((i) => i.id !== item.id) // Remove item
          : [...prev, item] // Add item
    );
  };
  
  const handleApply = () => {
    onSelect(selectedItems, dataType);
    setSelectedItems([]);
  }

  return (
    <MainWrapper>
      <AiSuggestionContianer>
        <Header>
          <CloseBtn onClick={onClose}>
            <img src={MenuExpandLeft} alt="" />
          </CloseBtn>
          
          <HeaderWrap>
            <HeaderTitle>AI Assistant</HeaderTitle>
            <Image src={Stars} />
          </HeaderWrap>
        </Header>

        <SubHeader>
          <SubHeaderTitle>Enable Suggestions</SubHeaderTitle>
          <Switch onChange={handleSwitchToggle} />
        </SubHeader>
      </AiSuggestionContianer>

      <Body>
        {isSwitch && (
          loading ? (
            <Loader
              title={data[step-1].title}
              loading={loading}
              onRefresh={handleRefresh}
            />
          ) : (
            <>
              <ChipsWrapper>
                {items.slice(0, isShowMore ? items.length : 5).map(item =>
                  <Option
                    data={item}
                    isSelected={selectedItems.some((i) => i.id === item.id)}
                    key={item.id}
                    type={(step === 1 || step === 2) ? 'checkbox' : 'radio'}
                    onChange={handleSelect}
                  />
                )}
              </ChipsWrapper>

              <Footer>
                {(step > 2 || isShowMore) ? null : <Button styleType="link-primary" onClick={() => setIsShowMore(true)}>Show More</Button>}

                {(step <= 2 && isShowMore) || (step > 2 && step !== 7) ? (
                  <RegGernerateButton onClick={handleRegenerate}>
                    <Image src={ReLoad} /> Regenerate
                  </RegGernerateButton>
                ) : null}

                <Button onClick={handleApply} disabled={!selectedItems.length}>Apply</Button>
              </Footer>

              {step === 7 && (
                <Wrapper>
                  <RegGernerateButton>Copy</RegGernerateButton>
                  <RegGernerateButton>Apply</RegGernerateButton>
                </Wrapper> 
              )}
            </>
        ))}
      </Body>
    </MainWrapper>
  );
};

export default AIHelper;
