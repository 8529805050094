import { FC, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner } from "elements";

import Areas from "./AreasList";
import { IArea } from "./AreaItem";

import {
  Wrapper,
  Background,

  Header,
  HeaderRow,
  HeaderHeading,
  HeaderTitle,
  HeaderText,
  CloseBtn,

  SpinnerWrapper,
  Body,

  GoalName,

  AreasFrame,
  AreasList,
  AreasImpact,
  AreaItem,

  Footer,
} from "./styled";

// @ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";

// @ts-ignore
import { ReactComponent as LeverageGreenIcon } from "assets/images/priorities/leverage-green.svg";

import { ListLeverageScores, UpdateLeverageScores } from "Redux/ListLeverageScores/action";

import { Instructions } from "../IntroTooltip";

interface IProps {
  isActive: boolean;
  color: string;
  goal: any;
  onClose: () => void;
}

const Popup: FC<IProps> = ({ isActive, color, goal, onClose }) => {
  const dispatch = useDispatch();

  const [isRendered, setIsRendered] = useState(isActive);
  const [isActiveState, setIsActiveState] = useState(false);
  const [data, setData] = useState<IArea[]>([]);

  const { data: leverageScoresData, loading, loading_update } = useSelector((state: any) => state.listLeverageScores);

  useEffect(() => {
    if (isActive) {
      setIsRendered(true);

      dispatch(ListLeverageScores(goal?.id));
  
      setTimeout(() => {
        setIsActiveState(true);
      }, 50);
    } else {
      setIsActiveState(false);
      setTimeout(() => setIsRendered(false), 250);
    }
  }, [isActive, goal]);

  useEffect(() => {
    if (leverageScoresData) {
      setData(leverageScoresData);
    }
  }, [leverageScoresData]);

  if (!isRendered) {
    return null;
  }

  const handleClose = () => {
    onClose();
  }

  const handleSubmit = () => {
    const payloadData = {
      updated_scores: {
        goal: goal?.id,
        scores: data
          .filter(({ value }) => value)
          .map(({ label, value }) => ({
            area_name: label,
            score: value,
        })),
      }
    }

    dispatch(UpdateLeverageScores(payloadData, () => {
      handleClose();
    }));
  }

  const disabled = loading || loading_update;

  const handleChange = (id: string, value: string | null) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          value,
        };
      }

      return item;
    });

    setData(newData);
  }

  const handleCheckAll = (isChecked: boolean) => {
    const newData = data.map((item) => ({
      ...item,
      value: isChecked ? "10.0" : null,
    }));

    setData(newData);
  };

  const averageScore = (() => {
    const totalScore = data.reduce((acc, { value }) => acc + (value ? parseFloat(value) : 0), 0);
    const itemCount = data.filter(({ value }) => value).length;
    return itemCount > 0 ? totalScore / itemCount : 0;
  })();

  return createPortal(
    <>
      <Wrapper $active={isActiveState}>
        <Header $color={color}>
          <HeaderRow>
            <HeaderHeading>
              <LeverageGreenIcon />
              <HeaderTitle>LEVERAGE SCORE <span>(Indirect Impact)</span></HeaderTitle>
            </HeaderHeading>

            <CloseBtn onClick={handleClose}>
              <CloseIcon />
            </CloseBtn>
          </HeaderRow>

          <HeaderText>The relative influence of this Goal on the other areas of your life.</HeaderText>
        </Header>

        {loading
          ? (
            <SpinnerWrapper>
              <Spinner variant="secondary" />
            </SpinnerWrapper>
          ) : (
          <Body>
            <GoalName>{goal?.goal_name || "Empty goal"}</GoalName>

            <AreasFrame>
              <AreasList>
                {data.map(({ id, color }) => <AreaItem key={id} $color={color} />)}
              </AreasList>
              <AreasImpact>{averageScore.toFixed(1)}</AreasImpact>
            </AreasFrame>

            <Areas
              data={data}
              onChange={handleChange}
              onCheckAll={handleCheckAll}
            />

            <Instructions onClose={handleClose} />
          </Body>
        )}

        <Footer>
          <Button styleType="secondary" onClick={isActiveState ? handleClose : undefined}>Cancel</Button>
          <Button disabled={disabled} onClick={handleSubmit}>Save</Button>
        </Footer>
      </Wrapper>

      <Background
        $active={isActiveState}
        onClick={isActiveState ? handleClose : undefined}
      />
    </>,
    document.getElementById("portal") as HTMLElement
  );
}

export default Popup;
