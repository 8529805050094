import { Tooltip } from "antd";
import { Tooltip as TooltipElement } from "elements";
import moment from "moment";
import { Checkbox } from "components/PriorityApp/SimpleView/Form";

import { ReactComponent as QuestionMarkIcon } from "assets/images/priorities/question-mark.svg";

import { ReactComponent as ReloadIcon } from "assets/images/journeys/reload.svg";
import { ReactComponent as EditIcon } from "assets/images/journeys/edit.svg";
import { ReactComponent as StartIcon } from "assets/images/journeys/rocket.svg";
import { ReactComponent as LockIcon } from "assets/images/journeys/lock-v2.svg";
import { ReactComponent as InfoIcon } from "assets/images/journeys/info-v2.svg";

import NewIcon from './assets/NewIcon';

import {
  Wrapper,
  StatusIcon,
  Content,
  Head,
  Title,
  Time,
  Body,
  Text,
  ViewAreas,
  Footer,
  CheckboxWrap,
  Button,
  PrivateInfo,
  PrivateBadge,

  ProgressWrapper,
  ProgressContent,
  ProgressLabel,
  ProgressPercent,
  Progress
} from "./styled";

const Status = {
  notStarted: "Not started",
  completed: "Completed",
  inProgress: "In progress",
};

const Color = {
  Personal: "#B15DF3",
  Professional: "#59A310",
  Business: "#1271A6",
};

const DarkColor = {
  Personal: "#7D3CB3",
  Professional: "#3C8600",
  Business: "#0F4D6D",
}

const JourneyItem = ({
  data,
  coachView,
  onStart,
  onContinue,
  onPrivateToggle,
  onViewAreas,
  setSelectedName,
}) => {
  const { id, name, type, description, current_state, subdomains } = data;

  const onStartClickHandle = () => {
    onStart(id);
  };

  const onContinueClickHandle = () => {
    onContinue(data);
  };

  const onPrivateChangeHandle = () => {
    onPrivateToggle(id);
  };

  const onViewAreasClickHandle = () => {
    const subdomainId = subdomains?.[0]?.id;

    setSelectedName(name);
    onViewAreas(subdomainId);
  };

  return (
    <Wrapper $color={Color[type]} data-id={id.toString()}>
      {!!data.legacy_domain ? <StatusIcon>
        <NewIcon color={Color[type]} darkColor={DarkColor[type]} />
      </StatusIcon> : null}

      <Content>
        <Head>
          <Title data-testid="card-title" $push={!!data.legacy_domain}>{name}</Title>
          <ViewAreas onClick={onViewAreasClickHandle}>View Areas</ViewAreas>
        </Head>
        <Body>
          {coachView && !current_state.is_private ? (
            <>
              {current_state.status === Status.completed && (
                <Time>Finished: {moment(current_state.created_at).fromNow()}</Time>
              )}

              {current_state.status === Status.inProgress && (
                <Time>Started: {moment(current_state.created_at).fromNow()}</Time>
              )}
            </>
          ) : <Time />}
          <Text>{description}</Text>
        </Body>
      </Content>

      {current_state.status !== Status.notStarted && (coachView && !current_state.is_private) && <ProgressWrapper>
        <ProgressContent>
          <ProgressLabel>Journey Progress</ProgressLabel>
          <ProgressPercent>{`${Math.ceil(current_state.progress) || 0}%`}</ProgressPercent>
        </ProgressContent>

        <Progress width={`${current_state.progress || 0}%`} />
      </ProgressWrapper>}

      <Footer>
        {coachView && current_state.is_private ? (
          <PrivateInfo>
            <PrivateBadge>
              <LockIcon />Private
            </PrivateBadge>

            <TooltipElement
              placement="topLeft"
              type="tertiary"
              title="This journey is private"
              text="Private journeys can’t be edited without owner permission."
              align={{
                offset: [0, -2]
              }}
            >
              <InfoIcon />
            </TooltipElement>
          </PrivateInfo>
        ) : null}

        {coachView ? <span></span> : <CheckboxWrap>
          <Checkbox
            checked={current_state.is_private}
            disabled={current_state.status === Status.notStarted}
            onChange={onPrivateChangeHandle}
          >
            Private
          </Checkbox>
          <Tooltip title="Domains can’t be edited without owner permission.">
            <QuestionMarkIcon />
          </Tooltip>
        </CheckboxWrap>}

        {coachView && current_state.is_private ? null : (
          <>
            {current_state.status === Status.notStarted && (
              <Button onClick={onStartClickHandle} disabled={coachView && current_state.is_private} data-testid="card-button">
                Start <StartIcon />
              </Button>
            )}
            {current_state.status === Status.completed && (
              <Button onClick={onContinueClickHandle} disabled={coachView && current_state.is_private} data-testid="card-button">
                Edit <EditIcon />
              </Button>
            )}
            {current_state.status === Status.inProgress && (
              <Button onClick={onContinueClickHandle} disabled={coachView && current_state.is_private} data-testid="card-button">
                Continue <ReloadIcon />
              </Button>
            )}
          </>
        )}
      </Footer>
    </Wrapper>
  );
};

export default JourneyItem;
