import { Link } from "react-router-dom";
import styled, { css } from 'styled-components';

import Button from "components/Button";
import BgImg from "assets/images/table_background.png";

export const Container = styled.div`
  width: 100%;
`;

export const TableHeadCol = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--neutral-500, var(--Input-header, #737373));
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;

  @media screen and (max-width: 743px) {
    text-transform: capitalize;
  }

  ${({ $width }) => $width && css`
    width: ${$width};
  `}

  ${({ $ml }) => $ml && css`
    margin-left: ${$ml};
  `}

  ${({ $mr }) => $mr && css`
    margin-right: ${$mr};
  `}

  ${({ $mt }) => $mt && css`
    margin-top: ${$mt};
  `}

  ${({ $pl }) => $pl && css`
    padding-left: ${$pl};
  `}

  ${({ $center }) => $center && css`
    text-align: center;
  `}

  ${({ $flex }) => $flex && css`
    flex: ${$flex || '1'};
    justify-content: flex-end;
  `}
`;

export const Step = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 22px;
  background-color: #fff;

  ${({ theme }) => theme.max("sm")`
    padding: 9px 16px;
  `}

  ${({ $footer }) =>
    $footer &&
    css`
      padding: 7px 22px;

      @media screen and (max-width: 1439px) {
        position: fixed;
        bottom: 0;
        background-color: #fff;
      }

      ${({ theme }) => theme.max("sm")`
      padding: 8px 16px;
    `}
    `}
`;

export const Heading = styled.h1`
  color: #06203a;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  vertical-align: middle;
  font-size: 18px;
  line-height: 24px;
  margin: 0;

  @media screen and (max-width: 743px) {
    font-size: 14px;
  }
`;

export const ActionButton = styled(Button)`
  ${({ theme }) => theme.max("sm")`
    width: 40px;
    height: 32px;
    font-size: 0;
    line-height: 0;
    padding: 0 8px;
    position: static;
  `}
`;

export const ButtonWrapper = styled.div`
  width: auto;
`;

export const NextButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

export const ContainerItem = styled.div`
  // background-image: url(${BgImg});
  width: 100%;
  // height: calc(100% - 52px - 56px);
  background-size: cover;
  background-position: center;
  padding-right: 0.5rem;

  ${({ theme }) => theme.max("sm")`
    height: calc(100% - 60px - 46px);
  `}
`;

export const TableContainer = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 743px) {
    margin-bottom: 100px;
    height: auto;
  }

  @media (min-width: 744px) and (max-width: 1024px) {
    margin-bottom: 70px;
    height: auto;
  }

  ${({ theme }) => theme.min("sm")`
    ::-webkit-scrollbar {
      width: 11px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #71aaca;
      border-radius: 10px;
      background: #71aaca;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #116595;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #116595;
    }
  `}
`;

export const Row = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border-left: 4px solid transparent;
  border-left-color: ${({ border }) => `${border}`};
  margin-bottom: 4px;
  padding: 0 9px 0 17px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.17);
  position: relative;
  ${({ theme }) => theme.max("sm")`
  padding-bottom: 10px;
  `}
`;

export const ItemContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row;
  
  @media screen and (max-width: 743px) {
    flex-direction: column;
    width: 80%;
    align-items: start;
  }
`;

export const LeftSide = styled.div`
  width: 30vw;
  display: flex;
  align-items: center;
  padding-left: 21px;
  padding-right: 5vw;

  @media screen and (max-width: 743px) {
    width: 100%;
    padding-left: 1px;
    padding-right: 3vw;
  }

  @media (min-width: 744px) and (max-width: 1024px) {
    width: 49%;
    padding-right: 1vw;
    padding-left: 5px;
    margin-top: 5px;
  }

`;

export const Count = styled.p`
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  line-height: 56px;
  color: #000;
  margin-bottom: 0;
  min-width: 18px;

  @media screen and (max-width: 743px) {
    width: 20px;
    margin : 50px 2px 0 2px;
  }
`;

export const Text = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0;
  color: var(--neutral-900, var(--Color-2, #171717));
  margin-right: 16px;

  @media screen and (max-width: 770px) {
    width: auto;
    padding-right: 8px;
    line-height: 42px;
  }
`;

export const TextRight = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0;
  color: var(--neutral-900, var(--Color-2, #171717));
  margin-left: 21px;
  margin-right: 16px;

  @media screen and (max-width: 770px) {
    width: auto;
    padding-right: 8px;
    line-height: 42px;
  }
`;

export const Plus = styled.span`
  font-size: 1.25rem;
  padding-left: 0.25rem;

  ${({ theme }) => theme.max("sm")`
    padding-left: 0;
  `}
`;

export const RightSide = styled.div`
  width: 30vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 4vw;

  @media screen and (max-width: 743px) {
    width: 100%;
    padding-right: 3vw;
  }
  
  @media (min-width: 744px) and (max-width: 1024px) {
    width: 49%;
    padding-right: 1vw;
    padding-left: 5px;
    margin-top: 5px;
  }
  
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  z-index: 999;
  position: relative;
  margin-left: 21px;

  .ant-btn-compact-first-item {
    display: none;
  }

  .ant-btn {
    background-color: transparent;
    border: none;
    transform: rotate(90deg);
  }

  @media screen and (max-width: 743px) {
    margin-top: 15px;
    flex-direction: column;
    gap: 40px;
    margin-left: 1px;
    margin-right: 5px;
  }

  @media (min-width: 744px) and (max-width: 1024px) {
    margin-left: 5px;
    margin-right: 7px;
  }

`;

export const ModifiedButton = styled(Button)`
  background: linear-gradient(161.19deg, #1263de 18.35%, #448fff 74.45%);
`;

export const Icon = styled.img`
  width: 0.8rem;
  height: 0.8rem;
`;

export const SwitchWrapper = styled.div`
  font-size: 0.5rem;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  padding-left: 4px;
  /* pointer-events: none; */
`;

export const Ul = styled.ul`
  list-style: none;
  padding: 0;
  width: 180px;
  padding: 0.2rem 0;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  right: 65%;
  top: 18%;

  @media screen and (max-width: 743px) {
    position: absolute;
    right: 65%;
    top: -10%;
  }
`;

export const Li = styled.li`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 21px;
  padding-right: 16px;
  height: 36px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ color }) => color};

  &:hover {
    background-color: #ececec;
  }
`;

export const IconButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  width: 1.6rem;
  opacity: ${({ disabled }) => disabled && "0.5"};

  @media screen and (max-width: 743px) {
    width: 100%;
  }

  @media (min-width: 744px) and (max-width: 1024px) {
    width: 1.4rem;
  }

`;

export const PrimaryLink = styled(Link)`
  color: var(--primary-500-main, #1271A6);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 6px 0;
  gap: 6px;
  text-decoration: none;
`;

export const AddButton = styled.button`
  border: none;
  background-color: transparent;
  color: var(--primary-500-main, #1271A6);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 6px 0;
  gap: 6px;

  :disabled {
    opacity: .5;
  }
`;

export const ActionsRow = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (min-width: 744px) and (max-width: 1024px) {
    justify-content: flex-start;
  }

  ${({ $right }) => $right && css`
    position: absolute;
    right: 30px;
    top: 42px;

    @media screen and (max-width: 743px) {
      top: 65px;
    }
  `}


`;

export const DropdownButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  padding: 0;
  background: transparent;
  color: #FF9900;

  svg {
    margin-right: 4px;
  }

  &:disabled {
    opacity: .5s;
  }
`;

export const DropdownItem = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1B2A3D;
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;

  &:disabled {
    opacity: .5s;
  }
`;
