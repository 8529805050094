import { listFocusedPrioritise } from "../APM/ListFocusedPriorities/action";
import { GetActionsProgress } from "Redux/GetProgress/action";
import { ActionTypes } from "./types";

export const SetSelectedGoalAction = (plan, fetchProgress = true) => async (dispatch) => {
  if (plan && plan.goal && plan.goal.id && fetchProgress) {
    dispatch(GetActionsProgress(plan.goal.id));
  }

  dispatch({
    type: ActionTypes.SELECTED_GOAL_ACTION,
    payload: plan,
  });
};

export const SelectedGoalAddAction = action => async (dispatch) => {
  dispatch({
    type: ActionTypes.SELECTED_GOAL_ACTION_ADD_ACTION,
    payload: action
  });
};

export const SelectedGoalRemoveAction = id => async (dispatch) => {
  dispatch({
    type: ActionTypes.SELECTED_GOAL_ACTION_REMOVE_ACTION,
    payload: id
  });
  dispatch(listFocusedPrioritise());
};

export const ToggleSidebarAction = (sidebar) => async (dispatch) => {
  dispatch({
    type: ActionTypes.TOGGLE_SIDEBAR_ACTION,
    payload: sidebar,
  });
};

export const DrawerMenuAction = (status) => async (dispatch) => {
  dispatch({
    type: ActionTypes.DRAWER_MENU_ACTION,
    payload: status,
  });
};

export const ToggleInfoSidebarAction = payload => async (dispatch) => {
  const action = {
    type: ActionTypes.TOGGLE_INFO_SIDEBAR_ACTION,
  };

  if (typeof payload === 'boolean') {
    action.payload = payload;
  }

  dispatch(action);
};

export const setIsDisabledStep = (disable) => async (dispatch) => {
  dispatch({
    type: ActionTypes.IS_DISABLED,
    payload: disable,
  });
};
export const setSelectedClient = (client) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SELECTED_CLIENT,
    payload: client,
  });
};
