import { createMasterplanSelectJourney } from "api";
import { Error } from "Responses/Response";
import { getCookie, setCookie } from "utils/StorageVariables";
import { ActionTypes } from "./types";

export const CreateMasterplanSelectJourney =
  (Data, name, id, onSuccess) => async (dispatch) => {
    try {
      const userDetails = JSON.parse(getCookie("userData"));
      const selectedClient = getCookie("selectedClient");
      let query = ``;
      if (userDetails?.type === "Coach" && selectedClient) {
        query = `?coaching_user=${selectedClient}`;
      }
      dispatch({ type: ActionTypes.LOADING });
      const { data, status } = await createMasterplanSelectJourney(Data, query);
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data,
      });
      if (status === 201) {
        setCookie("MasterPlanId", data?.id);
        setCookie("JourneyId", data?.journey?.id);

        onSuccess(id, name);
      }
    } catch (error) {
      Error(error?.response?.data?.error);
      dispatch({
        type: ActionTypes.ERROR,
        payload: error?.response?.data[0],
      });
    }
  };
export const CreateMasterplanSetError = (decision) => async (dispatch) => {
  dispatch({
    type: ActionTypes.ERROR,
    payload: decision,
  });
};
