import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { Spinner } from "elements";

interface RootState {
  loading: boolean;
  listCategoryReducer: { loading: boolean };
  selectAreaAndCategoriesReducer: { loading: boolean };
  domainListReducer: { loading: boolean };
  deleteUserAreaReducer: { loading: boolean };
  deleteActionReducer: { loading: boolean };
  accountabilityReducer: { loading: boolean };
  createActionReducer: { loading: boolean };
  prioritizeActionPlansReducer: { loading: boolean };

  // General
  getMyClientListReducer: { loading: boolean };

  // APM
  listFocusedPrioritise: { loading: boolean };
  unprioritizedReducer: { loading: boolean };
  RetrieveMasterPlanDetailsReducer: { loading: boolean };
  updateTimeLine: { loading: boolean };
  weeklyDuration: { loading: boolean };
  prioritizedActions: { loading: boolean };
  createPrioritizedActivities: { loading: boolean };
  availableAreas: { loading: boolean };
  updateIndirectScores: { loading: boolean };
  updateFocusedPriorities: { loading: boolean };
  prioritizedtoFocused: { loading: boolean };
  createAction: { loading: boolean };
  createTask: { loading: boolean };
}

const useLoadingState = () => {
  const { loading: listCategoryLoading } = useSelector(
    (state: RootState) => state.listCategoryReducer
  );
  const { loading: selectAreaAndCategoriesLoading } = useSelector(
    (state: RootState) => state.selectAreaAndCategoriesReducer
  );
  const { loading: masterPlanDetailsLoading } = useSelector(
    (state: RootState) => state.RetrieveMasterPlanDetailsReducer
  );
  const { loading: domainListLoading } = useSelector(
    (state: RootState) => state.domainListReducer
  );
  const { loading: deleteUserAreaLoading } = useSelector(
    (state: RootState) => state.deleteUserAreaReducer
  );
  const { loading: deleteActionLoading } = useSelector(
    (state: RootState) => state.deleteActionReducer
  );
  const { loading: listAccountabilityLoading } = useSelector(
    (state: RootState) => state.accountabilityReducer
  );
  const { loading: createActionItemLoading } = useSelector(
    (state: RootState) => state.createActionReducer
  );
  const { loading: prioritizeActionItemsLoading } = useSelector(
    (state: RootState) => state.prioritizeActionPlansReducer
  );

  // general
  const { loading: getMyClientListLoading } = useSelector(
    (state:RootState) => state.getMyClientListReducer
  );

  // apm loader
  const { loading: listFocusedPrioritise } = useSelector(
    (state: RootState) => state.listFocusedPrioritise
  );
  const { loading: unprioritizedReducer } = useSelector(
    (state: RootState) => state.unprioritizedReducer
  );
  const { loading: updateTimeLine } = useSelector(
    (state: RootState) => state.updateTimeLine
  );
  const { loading: weeklyDuration } = useSelector(
    (state: RootState) => state.weeklyDuration
  );
  const { loading: prioritizedActions } = useSelector(
    (state: RootState) => state.prioritizedActions
  );
  const { loading: createPrioritizedActivities } = useSelector(
    (state: RootState) => state.createPrioritizedActivities
  );
  const { loading: availableAreas } = useSelector(
    (state: RootState) => state.availableAreas
  );
  const { loading: updateIndirectScores } = useSelector(
    (state: RootState) => state.updateIndirectScores
  );
  const { loading: updateFocusedPriorities } = useSelector(
    (state: RootState) => state.updateFocusedPriorities
  );
  const { loading: prioritizedtoFocused } = useSelector(
    (state: RootState) => state.prioritizedtoFocused
  );
  const { loading: createAction } = useSelector(
    (state: RootState) => state.createAction
  );
  const { loading: createTask } = useSelector(
    (state: RootState) => state.createTask
  );

  const isLoading = [
    listAccountabilityLoading,
    listCategoryLoading,
    selectAreaAndCategoriesLoading,
    masterPlanDetailsLoading,
    domainListLoading,
    deleteUserAreaLoading,
    deleteActionLoading,
    createActionItemLoading,
    prioritizeActionItemsLoading,

    // general
    getMyClientListLoading,

    // apm loader
    listFocusedPrioritise,
    unprioritizedReducer,
    updateTimeLine,
    weeklyDuration,
    prioritizedActions,
    createPrioritizedActivities,
    availableAreas,
    updateIndirectScores,
    updateFocusedPriorities,
    prioritizedtoFocused,
    createAction,
    createTask
  ].includes(true);
  return isLoading;
};

const Loading: React.FC = () => {
  const isLoading = useLoadingState();

  const [isActive, setIsActive] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isLoading) {
      setShouldRender(true); // Show component immediately

      timeoutId = setTimeout(() => {
        setIsActive(true); // Activate the animation after 50ms
      }, 50);
    } else {
      setIsActive(false); // Deactivate the animation

      timeoutId = setTimeout(() => {
        setShouldRender(false); // Hide the component after 200ms (when the animation is done)
      }, 200);
    }

    return () => clearTimeout(timeoutId);
  }, [isLoading]);

  if (!shouldRender) {
    return null;
  }

  return (
    <Wrapper $active={isActive}>
      <Spinner />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #00000049;
  z-index: 1100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 9999999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;

  ${({ $active }) =>
    $active &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export { useLoadingState };
export default Loading;
