import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";
import { Popover, ArrowContainer,PopoverPosition } from 'react-tiny-popover';
import { AreaData } from "../PriorityEdit/Impact";

interface PopoverCustomProps {
  positions?: PopoverPosition[];
  content: React.ElementType | React.ReactNode;
  wrapperStyle?: object;
  popoverStyle?: {
    containerStyle?: {
      zIndex?: string;
      left?: string;
      top?: string;
      height? : string;
      position?: string;
    };
    arrowStyle?: object;
    buttonStyle?: string;
  };
  hover?: boolean;
  height?: string;
  close?: boolean;
  children: React.ReactNode;
  defaultBoundaryElement?: boolean;
  width?: string;
  keepOpen?: boolean;
  onClose?: (areaData:AreaData[]) => void;
  handleClose?: (value: number[]) => void;
  setVisible?: (value: boolean) => void;
  isClick?: boolean;
  customRef?: HTMLDivElement
}

const PopoverCustom = forwardRef(({ positions = ["bottom","top"], content: Content, wrapperStyle,customRef, popoverStyle, hover, height, keepOpen, close, children, defaultBoundaryElement, onClose, handleClose,setVisible , isClick, width }: PopoverCustomProps, ref) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    setIsPopoverOpen(false);
  }, [close]);

  useEffect(() => {
    if(!isClick){
      hover ? setIsPopoverOpen(true) : setIsPopoverOpen(false);
    }
  }, [hover]);

  useImperativeHandle(ref, () => ({
    close: () => {
      setIsPopoverOpen(false);
    }
  }));

  const boundaryRef = useRef<HTMLDivElement>(null);

  const onClickOutsideHandle = (evt: MouseEvent) => {
    if (keepOpen)
      return;

    const target = evt.target as Element;
    setVisible && setVisible(false);
     
    if (!target?.classList?.contains("ant-picker-range-wrapper")
      && !target?.closest(".ant-picker-range-wrapper")
      && !target.closest(".ant-picker")
      && !target.closest(".ant-picker-dropdown")
      && !target.closest(".ant-picker-header")
      && !target.closest(".ant-picker-body")
      && !target.closest(".ant-picker-date-panel"))
       {
      setIsPopoverOpen(false);

      if (onClose && typeof onClose === 'function') {
        onClose([]);
      }

      if (handleClose && typeof handleClose === 'function') {
        handleClose([]);
      }
    }
  }

  const handleToggle = () => {
    setIsPopoverOpen(!isPopoverOpen);

    if (onClose && typeof onClose === 'function' && isPopoverOpen) {
      onClose([]);
    }
  };

  return (
    <div ref={customRef as any} style={{ height: height, ...wrapperStyle }} >
      <Popover
        data-testid="custom-popover-props"
        // isOpen={true}
        isOpen={isPopoverOpen}
        containerStyle={{
          // filter: "drop-shadow(0px 0px 10px rgba(0,0,0,0.25))",
          zIndex: popoverStyle?.containerStyle?.zIndex ? popoverStyle?.containerStyle?.zIndex : '99999',
          left: popoverStyle?.containerStyle?.left ,
          top: popoverStyle?.containerStyle?.top,
          width: !!width ? width : '365px',
          // height:"250px",
          height:popoverStyle?.containerStyle?.height,
        }}
        reposition={true}
        positions={positions}
        {...defaultBoundaryElement ? {} : boundaryRef.current ? {boundaryElement: boundaryRef.current} : {}}
        padding={10}
        boundaryInset={60}
        onClickOutside={onClickOutsideHandle}
        
        content={({ position, childRect, popoverRect, ...rest }) => (
          <ArrowContainer
            popoverRect={popoverRect}
            childRect={childRect}
            position={position}
            
            //@ts-ignore
            targetRect={{ top: '20px' }}
            arrowColor={'#fff'}
            arrowSize={6}
            arrowStyle={popoverStyle?.arrowStyle}
          >
            {typeof Content === 'function' ? <Content
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              onClose={() => setIsPopoverOpen(false)}
              {...rest}

       
            /> : <div className="hunza" data-testid="custom-popover">{Content}</div> }
          </ArrowContainer>
        )}
      >
        <Toggler $align={popoverStyle?.buttonStyle} type="button" onClick={handleToggle}>
          {children}
        </Toggler>
      </Popover>
    </div>
  );
});

export const Toggler = styled.button<{ $align?: string }>`
    width: 100%;
    padding: 0;
    border: none;
    background-color: transparent;
    outline: none;
    
    padding-bottom: ${({ $align }) => $align ? $align : ''};
    align-self: ${({ $align }) => $align ? $align : 'auto'};
  };
`
export default PopoverCustom;