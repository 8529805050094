import styled, { css } from "styled-components";

import { Row } from "antd";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: .6;

  ::after {
    content: "";
    background: linear-gradient(rgba(251, 252, 252, 0) 47.65%, rgb(251, 252, 252) 100%);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 75%;
    pointer-events: none;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 14px 12px 0;
  border-bottom: 1px solid #A3A3A3;
`;

export const Icon = styled.img``;

export const Heading = styled.h2`
  margin: 0;
  padding: 0;
  color: #112538;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.68px;
  text-transform: uppercase;
`;

export const AiSugesstionText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: #525252;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const RolesHeading = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 4px;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--Placeholder, #A3A3A3);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 60%;
    height: calc(100% + 2px);
    right: -6px;
    top: -1px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FBFCFC 66.7%);
  }
`;

export const MissionHeading = styled.h3`
  margin: 0;
  color: #262626;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;
`;

export const RolesSpan = styled.span`
  color: #525252;
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
`;

export const RoleHeading = styled.span`
  color: #171717;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-right: 8px;
`;

export const CustomTable = styled.div`
  width: 100%;
  padding: 13px 10px;
  background-color: #f5f5f5;
  border: 1px solid #cdcdcd;
`;

export const CustomRow = styled(Row)<{ $isRow?: boolean; }>`
  display: flex;
  flex-direction: ${({ $isRow }) => ($isRow ? "none" : "column")};
  padding: 0.5rem;
`;

export const RowHeading = styled.h2`
  margin: 0;
  padding: 0;
  color: #262626;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

export const RowText = styled.p`
  margin: 0;
  padding: 0;
  color: #4c545b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;