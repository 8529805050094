import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: transparent;
  transition: background .2s;

  ${({ $active }) => $active && `
    background: #ECF3F6;
  `}
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px 0 0;
  height: 44px;
  gap: 8px;
`;

export const Toggle = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  padding: 6px 10px;
  border: none;
  background: transparent;
  text-align: left;
  height: 44px;

  svg {
    transform: rotate(0deg);
    transition: transform .2s;
  }

  :hover {
    background: #ecf3f6;
  }

  ${({ $active }) => $active && `
    svg {
      transform: rotate(90deg);
    }

    :hover {
      background: #dde8ed;
    }
  `}
`;

export const Title = styled.span`
  color: var(--fill-frame-prussian-blue, #1B2A3D);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  ${({ $active }) => $active && `
    color: var(--primary-500-main, #1271A6);
  `}
`;

export const Delete = styled.button`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  background: transparent;

  :disabled {
    opacity: 0.5;
  }
`;

export const Body = styled.div`
  width: 100%;
`;

export const BodyInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 12px 0 12px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
