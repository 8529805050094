import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 32px 48px;
  gap: 32px;

  ${({ theme }) => theme.max("sm")`
    padding: 16px;
    min-width: 100%;
    max-width: 100%;
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 16px;
`;

export const Icon = styled.img`
  width: 40px;
  height: 40px;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.span<{ $sm?: boolean }>`
  color: #111827;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;

  ${({ $sm }) => $sm && `
    font-size: 16px;
  `}
`;

export const Text = styled.span`
  color: #6B7280;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
`;

export const Body = styled.div`
  border-radius: 16px;
  border: 1px solid rgba(54, 112, 93, 0.10);
  background: rgba(54, 112, 93, 0.02);
`;

interface IGroup {
  $last?: boolean;
}

export const Group = styled.div<IGroup>`
  display: flex;
  padding: 32px 48px;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
  position: relative;

  ${({ $last }) => $last && `
    padding-bottom: 0;

    ::before {
      content: "";
      height: 1px;
      width: calc(100% - 48px - 48px);
      position: absolute;
      top: 0;
      left: 48px;
      background: rgba(0, 0, 0, 0.05);
    }
  `}

  ${({ theme }) => theme.max("lg")`
    flex-direction: column;

    ::before {
      content: "";
      height: 1px;
      width: calc(100% - 48px - 48px);
      position: absolute;
      top: 0;
      left: 48px;
      background: rgba(0, 0, 0, 0.05);
    }

    ${({ $last }: IGroup) => $last && `
      padding-bottom: 32px;

      ::before {
        content: none;
      }
    `}
  `}

  ${({ theme }) => theme.max("sm")`
    padding: 16px;
    margin-bottom: 16px;
    gap: 28px;
  `}
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FormLabel = styled.span`
  padding-left: 8px;
  color: #737373;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

export const FormInput = styled.span`
  color: rgba(23, 23, 23, 0.50);
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #E5E5E5;
  background: #FFF;
  position: relative;
  margin-bottom: 32px;

  &::after {
    content: "";
    position: absolute;
    width: 40%;
    height: 44px;
    right: -6px;
    top: -6px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FBFCFC 66.7%);
  }
`;

export const Info = styled.div`
  display: flex;
  padding: 0 10px;
  gap: 16px;
`;

export const Counter = styled.span`
  font-family: Inter;
  min-width: 24px;
  height: 24px;
  color: #509E84;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 24px;
  border: 1px solid rgba(120, 189, 166, 0.50);
  background: rgba(120, 189, 166, 0.15);
`;

export const Video = styled.img`
  width: 100%;
  max-width: 420px;
  margin-top: 16px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const Tutorial = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;

  span {
    color: #6B7280;
    font-family: Inter;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const TutorialText = styled.span`
  color: #6B7280;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
`;

export const TutorialBadge = styled.div`
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background: rgba(120, 189, 166, 0.19);

  img {
    position: relative;
    top: -1px;
  }

  span {
    color: #509E84;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
  }
`;
