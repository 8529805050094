import { Col } from "antd";

import {
  Wrapper,
  Header,
  Icon,
  Heading,
  AiSugesstionText,
  RolesHeading,
  MissionHeading,
  RoleHeading,
  RolesSpan,
  CustomTable,
  CustomRow,
  RowHeading,
  RowText
} from "./styled";

// @ts-ignore
import msIcon from "assets/svg/statmentIcon.svg";

const MissionStatement = () => {
  return (
    <Wrapper>
      <Header>
        <Icon src={msIcon} />
        <Heading>Mission Statement</Heading>
      </Header>

      <RolesHeading>
        <RoleHeading>Health</RoleHeading>
        <RolesSpan>Sleep</RolesSpan>
      </RolesHeading>

      <MissionHeading>Mission Statement</MissionHeading>

      <AiSugesstionText>
        In the Area of Health, I focus on Sleep.
        I value Alertness in my roles of Entrepreneur and Performer.
        I believe that achievement depends on having enough rest and recovery time to think clearly, make optimal decisions and perform at my best to propel me toward my goals.
      </AiSugesstionText>

      <CustomTable>
        <CustomRow $isRow>
          <Col span={11}>
            <RowHeading>Roles</RowHeading>
            <RowText>Entrepreneur, Performer</RowText>
          </Col>
          <Col span={11}>
            <RowHeading>Values</RowHeading>
            <RowText>Alertness</RowText>
          </Col>
        </CustomRow>
        <CustomRow>
          <RowHeading>Principles</RowHeading>
          <RowText>Accountability is crucial to building and sustainingpositive actions in the long 
          term and will propel me toward my goals.</RowText>
        </CustomRow>
      </CustomTable>
    </Wrapper>
  );
}

export default MissionStatement;
