import { useSelector } from 'react-redux';

import LineChart from '../LineChart';
import Progress from '../Progress';

import {
  Wrapper,
  Content,

  Header,
  Col,
  TitleWrap,
  Icon,
  Title,
  Text,

  SuccessValue,
  SuccessText,
  
  Side,
  SideHr
} from './styled';

// @ts-ignore
import timelineIcon from "assets/images/achievements/timeline.svg";

import { IState } from '@types';

import { useQueryParams } from 'utils/useQueryParams';

const OverallTrend = () => {
  const { journey } = useQueryParams();

  const { data } = useSelector((state: IState) => state.listOverallProgress);
  const { data: historicalData, correlationScore } = useSelector((state: IState) => state.listHistoricalData);

  const { goal_progress, plan_progress } = data;

  return (
    <Wrapper>
      <Content>
        <Header>
          <Col>
            <TitleWrap>
              <Icon src={timelineIcon} alt="" />
              <Title>Overall Trend</Title>
            </TitleWrap>
            <Text>Overall progress summary</Text>
          </Col>

          <Col $right>
            <SuccessValue>{correlationScore}</SuccessValue>
            <SuccessText>Effectiveness Score</SuccessText>
          </Col>
        </Header>

        <LineChart data={historicalData} />
      </Content>

      <Side>
        <Progress
          type="goal"
          style={journey ? "secondary" : "primary"}
          title="Goal Completion Rate"
          value={goal_progress.progress % 1 === 0 ? goal_progress.progress : goal_progress.progress.toFixed(1)}
          text={`${goal_progress.completed_goals}/${goal_progress.total_goals} Goals Completed`}
        />
        <SideHr />
        <Progress
          type="plan"
          style={journey ? "secondary" : "primary"}
          title="Plan Progress"
          value={plan_progress.progress % 1 === 0 ? plan_progress.progress : plan_progress.progress.toFixed(1)}
          text={`${plan_progress.completed_actions}/${plan_progress.total_actions} Actions complete`}
        />
      </Side>
    </Wrapper>
  );
}

export default OverallTrend;
